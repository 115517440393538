/**
 * @name: index
 * @author: Gzm
 * @date: 2023-07-27 14:05
 * @description：index
 * @update: 2023-07-27 14:05
 */

import {get, postJ} from "@/request";
import {ISaleOrderQuery} from "@/apis/sale/order/types";
import {IEditGoodsStock, IGoodsStockQuery} from "@/apis/sale/availableInventory/types";

/**
 * 销售订单-分页查询
 * @param query
 */
export const saleOrderQueryApi = (query: ISaleOrderQuery) => get("/mall/saleOrder/query", query)

/**
 * 销售订单导出Excel
 * @param query
 */
export const saleOrderExportOrderApi = (query: ISaleOrderQuery) => postJ("/mall/saleOrder/exportOrder", query, "blob")

/**
 * 销售订单修改
 * @param query
 */
export const saleOrderModifyApi = (query: any) => postJ("/mall/saleOrder/modify", query, )

/**
 * 详情
 * @param id id
 * @returns
 */
export const saleOrderDetailApi = (id: string) => get("/mall/saleOrder/detail/" + id);

/**
 * 打印小票
 * @param ids ids
 * @returns
 */
export const saleOrderBatchPrintApi = (ids: string) => get("/mall/saleOrder/batchPrint" ,{ids});

