
/**
 * @name: order
 * @author: Gzm
 * @date: 2023-07-27 13:58
 * @description：销售管理-订单管理
 * @update: 2023-07-27 13:58
 */
import {Vue, Component} from "vue-property-decorator"
import {ICrudOption} from "@/types/m-ui-crud";
import {
  mallGoodsStockExportApi,
  mallGoodsStockQueryByPageApi,
  mallGoodsStockDetailApi,
  mallGoodsStockInventoryApi
} from "@/apis/site/productStock"
import {IProductStockParam} from "@/apis/site/productStock/types"
import {mallSiteQueryByListApi} from "@/apis/site/list"
import {deepCopy, exportFile} from "@/utils/common";
import {
  goodsStockModifyApi,
  goodsStockQuerySaleByPageApi,
  goodsStockSaleExportApi, printersRemoveApi
} from "@/apis/sale/availableInventory";
import {IGoodsStockQuery} from "@/apis/sale/availableInventory/types";
import {ISaleOrderQuery} from "@/apis/sale/order/types";
import {
  saleOrderBatchPrintApi,
  saleOrderExportOrderApi,
  saleOrderModifyApi,
  saleOrderQueryApi
} from "@/apis/sale/order";
import {IPurchaseOrderQuery} from "@/apis/purchase/types";
import {IOrderRefunded} from "@/apis/afterSales/refunded/types";
import router from "@/router";


@Component({})
export default class productStockPage extends Vue {
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: any[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: ISaleOrderQuery = {
    page: 1,
    limit: 10,
  }
  payPriceTotal = ''
  refundPriceTotal = ''
  editDialog = false
  // 表单数据
  modelForm: Partial<any> = {}
  // 配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [

      {
        label: "订单编号",
        prop: "saleOrderSn",
        align: "left",
        width: 200,
        editHide: true,
        search: true,
        slot: true
      },
      {
        label: "客户",
        prop: "userName",
        hide: true,
        search: true,
        editHide: true,
        placeholder: "输入客户昵称/电话模糊搜索"
      },
      {
        label: "客户昵称",
        prop: "nickName",
        align: "center",
        width: "100"
      },
      {
        label: "客户电话",
        prop: "userPhone",
        align: "center",
        width: "100"
      },
      {
        label: "商品数量",
        prop: "goodsQuantity",
        align: "center",
      },
      {
        label: "实付金额",
        prop: "payPrice",
        align: "center",
      },
      {
        label: "支付方式",
        prop: "payWay",
        align: "center",
        type: "select",
        dicData: [
          {
            label: "微信支付",
            value: 1
          },
        ]
      },
      {
        label: "退款金额",
        prop: "refundPrice",
        align: "center",
      },
      {
        label: "订单状态",
        prop: "status",
        align: "center",
        search: true,
        type: "select",
        editHide: true,
        dicData: [
          {
            label: "待支付",
            value: 1
          },
          {
            label: "备货中",
            value: 2
          },
          {
            label: "待配送",
            value: 3
          },
          {
            label: "配送中",
            value: 4
          },
          {
            label: "已完成",
            value: 5
          },
          {
            label: "退款取消",
            value: 6
          },
          {
            label: "自动取消",
            value: 7
          },
          {
            label: "手动取消",
            value: 8
          }
        ]
      },

      {
        label: "收货信息",
        prop: "receiptAddress",
        align: "center",
        overHidden: true,
        slot: true,
        width: "250"
      },
      {
        label: "站点",
        prop: "siteName",
        align: "center",
        overHidden: true,
      },
      {
        label: "站点",
        prop: "siteId",
        search: true,
        type: "select",
        dicData: [],
        hide: true,
        editHide: true
      },
      {
        label: "骑手",
        prop: "riderName",
        align: "center",
        search: true
      },
      {
        label: "下单时间",
        prop: "orderTime",
        align: "center",
        type: "daterange",
        search: true,
        width: "150"
      },
      {
        label: "要求送达时间",
        prop: "askArriveTime",
        align: "center",
        type: "daterange",
        search: true,
        width: "150"
      },
      {
        label: "实际送达时间",
        prop: "arriveTime",
        align: "center",
        type: "daterange",
        search: true,
        width: "150"
      },
      {
        label: "操作",
        prop: "menu",
        slot: true,
        align: "center",
        width: "150"
      }
    ]
  }

  getList() {
    this.tableLoading = true
    const form: ISaleOrderQuery = deepCopy(this.queryForm)
    if (form.orderTime && form.orderTime.length) {
      form.orderStartTime = form.orderTime[0]
      form.orderEndTime = form.orderTime[1]
    } else {
      form.orderStartTime = ""
      form.orderEndTime = ""
    }
    if (form.askArriveTime && form.askArriveTime.length) {
      form.askArriveStartTime = form.askArriveTime[0]
      form.askArriveEndTime = form.askArriveTime[1]
    } else {
      form.askArriveStartTime = ""
      form.askArriveEndTime = ""
    }
    if (form.arriveTime && form.arriveTime.length) {
      form.arriveStartTime = form.arriveTime[0]
      form.arriveEndTime = form.arriveTime[1]
    } else {
      form.arriveStartTime = ""
      form.arriveEndTime = ""
    }
    delete form.orderTime
    delete form.askArriveTime
    delete form.arriveTime
    saleOrderQueryApi(form).then(e => {
      this.tableTotal = e.saleOrderVoCommonPage.total
      this.tableData = e.saleOrderVoCommonPage.list
      this.refundPriceTotal = e.refundPriceTotal
      this.payPriceTotal = e.payPriceTotal
      this.tableLoading = false
    })
  }

  getSiteSelect() {
    mallSiteQueryByListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
    })
  }

  /**
   * 导出excel
   */
  exportExcel() {
    const form: ISaleOrderQuery = deepCopy(this.queryForm)
    if (form.orderTime && form.orderTime.length) {
      form.orderStartTime = form.orderTime[0]
      form.orderEndTime = form.orderTime[1]
    } else {
      form.orderStartTime = ""
      form.orderEndTime = ""
    }
    if (form.askArriveTime && form.askArriveTime.length) {
      form.askArriveStartTime = form.askArriveTime[0]
      form.askArriveEndTime = form.askArriveTime[1]
    } else {
      form.askArriveStartTime = ""
      form.askArriveEndTime = ""
    }
    if (form.arriveTime && form.arriveTime.length) {
      form.arriveStartTime = form.arriveTime[0]
      form.arriveEndTime = form.arriveTime[1]
    } else {
      form.arriveStartTime = ""
      form.arriveEndTime = ""
    }
    delete form.orderTime
    delete form.askArriveTime
    delete form.arriveTime
    saleOrderExportOrderApi(form).then(e => {
      exportFile(e, '销售订单.xlsx')
    })
  }

  editHandle(row: any) {
    this.modelForm = {}
    this.modelForm.id = row.id
    this.editDialog = true
  }

  printHandle(row: any) {
    this.$confirm('是否确认打印？', '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      saleOrderBatchPrintApi(row.id).then(e => {
        if (e) {
          this.$message.success("操作成功!")
          this.getList()
        }
      })
    }).catch(() => {
    })
  }

  submit() {
    if (!this.modelForm.orderRemarks) {
      return this.$message.error('请输入订单备注！')
    }
    saleOrderModifyApi(this.modelForm).then(e => {
      if (e) {
        this.$message.success('修改成功！')
        this.editDialog = false
        this.getList()
      }
    })
  }

  /**
   * 查看详情
   */
  openDetail(row: any, index: number) {
    let url = '/sale/detail'
    if (row)
      url = url + `?id=${row.id}`
    router.push({path: url})
  }


  created() {
    this.getList()
    this.getSiteSelect()
  }
}
